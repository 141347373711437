import { pluck, replace } from 'ramda'

export const getForbiddenSlugs = (naviItems) => {
  const allHrefs = [...naviItems['sk'], ...naviItems['en']]
  return pluck('href', allHrefs)
    .map(href => replace('/en', '', href))
    .map(href => href.split('/')[1])
    .filter(href => href !== '' && href !== 'en')
}

export const getViewportSize = () => {
  return {
    width: window.document.documentElement.clientWidth,
    height: window.document.documentElement.clientHeight,
  }
}
