import { pathOr } from 'ramda'

export const getLayoutState = (state) => pathOr({}, ['LAYOUT'], state)

export const getLayoutWidth = (state) => pathOr(0, ['width'], getLayoutState(state))

export const getLayoutHeight = (state) => pathOr(0, ['height'], getLayoutState(state))

export const getIsMobileLayout = (state) => getLayoutWidth(state) < 768

export const getIsMenuOpened = (state) => pathOr(false, ['menuOpened'], getLayoutState(state))

export const getIsLayoutExpanded = (state) => pathOr(false, ['expanded'], getLayoutState(state))

export const getPathname = (state) => pathOr('', ['router', 'location', 'pathname'], state)

export const getIsAppMounted = state => pathOr(false, ['appDidMount'], getLayoutState(state))

export const getIsSearchOpened = state => pathOr(false, ['searchOpened'], getLayoutState(state))
