import {
  APP_DID_MOUNT,
  COLLAPSE_LAYOUT,
  LAYOUT_RESIZED,
  MENU_TOGGLED,
  MENU_CLOSED,
  EXPAND_TOGGLED,
  MAP_LEFT_EDGE_CHANGED,
  SEARCH_TOGGLED,
} from './constants'
import { getViewportSize } from './helpers'

export const layoutResized = () => ({
  type: LAYOUT_RESIZED,
  payload: getViewportSize(),
})

export const toggleMenu = () => ({
  type: MENU_TOGGLED,
})

export const closeMenu = () => ({
  type: MENU_CLOSED,
})

export const toggleExpand = () => ({
  type: EXPAND_TOGGLED,
})

export const collapseLayout = () => ({
  type: COLLAPSE_LAYOUT,
})

export const mapLeftEdgeChanged = (x) => ({
  type: MAP_LEFT_EDGE_CHANGED,
  payload: {
    x,
  }
})

export const setAppDidMount = () => ({
  type: APP_DID_MOUNT,
})

export const toggleSearch = () => ({
  type: SEARCH_TOGGLED,
})
