import {
  CLOSE_BALLOON,
  SET_MAP_CENTER,
  SET_MAP_ZOOM,
  SET_CROSSHAIR_CENTER,
  PLACE_MOUSE_OVER,
  PLACE_MOUSE_OUT,
} from './constants'

export const closeBalloon = slug => ({
  type: CLOSE_BALLOON,
  payload: {
    slug,
  }
})

export const setCenter = (center) => ({
  type: SET_MAP_CENTER,
  payload: {
    center,
  },
})

export const setCrosshairCenter = (crosshairCenter) => ({
  type: SET_CROSSHAIR_CENTER,
  payload: {
    crosshairCenter,
  }
})

export const setZoom = (zoom) => ({
  type: SET_MAP_ZOOM,
  payload: {
    zoom,
  },
})

export const placeMouseOver = (slug) => ({
  type: PLACE_MOUSE_OVER,
  payload: {
    slug,
  }
})

export const placeMouseOut = () => ({
  type: PLACE_MOUSE_OUT,
})
