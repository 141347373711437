import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Layout from './containers/Layout/Layout'
import './styles/index.sass'

const App = () => {
  return (
    <div className="App full-height public-layout">
      <Switch>
        <Route path='/en/:categorySlug?/:placeSlug?' component={Layout} />
        <Route path='/:categorySlug?/:placeSlug?' component={Layout} />
      </Switch>
    </div>
  )
}

export default App
