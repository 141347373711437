import React, { Component } from 'react'
import { connect } from 'react-redux'
import { supportsLocalStorage } from '../../utils'
import { getIsAppMounted } from '../Layout/selectors'
import './CookieBar.sass'

class CookieBar extends Component {
  state = {
    isDismissed: supportsLocalStorage() ? global.localStorage.getItem('cookiesDismissed') : true
  }

  dismiss = () => {
    if (supportsLocalStorage()) {
      global.localStorage.setItem('cookiesDismissed', true)
      this.setState({ isDismissed: true })
    }
  }

  render() {
    if (!this.props.appDidMount || this.state.isDismissed) return null

    return (
      <div role="dialog" aria-live="polite" aria-label="cookieconsent" aria-describedby="cookieconsent:desc" className="cc-window cc-floating cc-type-info cc-theme-block cc-bottom">
        <span id="cookieconsent:desc" className="cc-message">
          Na tejto stránke používame súbory cookies. Ukladáme ich výhradne pre potreby Google Analytics, aby sme vedeli vyhodnotiť spôsob, akým našu stránku používate a do budúcna ju vylepšiť. Viac informácií o používaní súborov cookies zo strany Google Analytics sa dozviete 
          <a href="https://policies.google.com/technologies/types?hl=sk" target="_blank" rel="noopener noreferrer">tu</a>.
          <a aria-label="learn more about cookies" role="button" className="cc-link" href="/co-to-je" rel="noopener noreferrer nofollow" target="_blank">Viac...</a>
        </span>
        <div className="cc-compliance">
          <a
            aria-label="dismiss cookie message"
            role="button"
            className="cc-btn cc-dismiss"
            onClick={this.dismiss}
          >
            OK
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  appDidMount: getIsAppMounted(state),
})

export default connect(mapStateToProps)(CookieBar)
