import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { indexOrderComparator } from '../../helpers'
import { getCategorySlugs, getCategories, getCategoriesState } from '../../features/categories'
import { getLanguage } from '../../features/urlParams'

export const getPlaces = state => pathOr([], ['PLACES'], state)

export const getPlacesByLanguage = createSelector(
  getLanguage,
  getPlaces,
  (language, places) => {
    if (language === 'sk') {
      const filtered = places.map((place) => {
        const { en, ...newPlace } = place
        newPlace.locations = place.locations && place.locations.map((location) => {
          const { enDesc, ...locationRest } = location
          return locationRest
        })
        newPlace.categories = place.categories && place.categories.map((category) => {
          const { en, ...categoryRest } = category
          return categoryRest
        })
        return newPlace
      })
      
      return filtered.sort(indexOrderComparator)
    }

    const filtered = places
      .filter(place => place.en.title)
      .map((place) => {
        const { en, ...newPlace } = place
        newPlace.title = pathOr('unknown', ['title'], en)
        newPlace.slug = pathOr('unknown', ['slug'], en)
        newPlace.locations = place.locations && place.locations.map((location) => {
          const { enDesc, ...locationRest } = location
          locationRest.desc = enDesc
          return locationRest
        })
        newPlace.categories = place.categories && place.categories.map((category) => {
          const { en, ...categoryRest } = category
          return {
            ...categoryRest,
            title: pathOr('unknown', ['en', 'title'], category),
            slug: pathOr('unknown', ['en', 'slug'], category),
          }
        })
        return newPlace
      })

    return filtered.sort(indexOrderComparator)
  }
)

export const getPlacesByCategories = createSelector(
  getPlacesByLanguage,
  getCategorySlugs,
  getCategories,
  (places, slugs, categories) => {
    let result = {}
    slugs.forEach((slug) => {
      const items = places
        .filter(place => place.categories && place.categories.some(category => category.slug === slug))
        .sort((a, b) => {
          if (a.title < b.title) return -1
          if (a.title > b.title) return 1
          return 0
        })
      result[slug] = {
        title: categories.find(category => category.slug === slug).title,
        items,
      }
    })
    return result
  }
)

export const getPlaceData = (slug) => createSelector(
  getPlacesByLanguage,
  (places) => {
    const place = places.find(place => place.slug === slug)
    if (!place) return null
    const { title, images, content, locations } = place
    return {
      title,
      images,
      content,
      locations,
    }
  },
)

export const getPlaceTitle = (slug) => createSelector(
  getPlacesByLanguage,
  (places) => {
    const place = places.find(place => place.slug === slug)
    if (!place) return null
    return place.title
  },
)

export const getTranslationMap = createSelector(
  getPlaces,
  getCategoriesState,
  (places, categories) => ({
    categories: categories.map((c) => ({
      sk: c.slug,
      en: pathOr(null, ['en', 'slug'], c),
    })),
    places: places.map(p => ({
      sk: p.slug,
      en: pathOr(null, ['en', 'slug'], p),
    })),
  })
)
