import { MOBILE_HEADER_HEIGHT, MAP_DESKTOP_PADDING_LEFT } from '../../constants'

export const getLanguageFromPath = (pathname) => {
  const fragments = pathname.split('/')
  // english pages always start with /en
  return (fragments[1] === 'en') ? 'en' : 'sk'
}

export const mapDistanceToMouse = ({ x, y }, { x: mouseX, y: mouseY }) => Math.sqrt(
  (x - mouseX) *
  ((x - mouseX) + (y - mouseY)) *
  (y - mouseY),
)

export const getMapStyles = (layoutExpanded, isMobileLayout, viewportHeight) => {
  // desktop
  if (!isMobileLayout) {
    return { height: '100%' }
  }

  // mobile
  return layoutExpanded
    ? {
        height: viewportHeight - MOBILE_HEADER_HEIGHT,
      }
    : null
}

export const getMarkerState = (slugs = {}, categories = [], markerSlug = '') => {
  const { placeSlug } = slugs

  if (markerSlug === placeSlug) return 'active'
  
  const isActiveCategory = categories.findIndex(c => slugs.categorySlug === c.slug) > -1
  if (isActiveCategory && !placeSlug) return 'highlighted'
  
  return ''
}

export const getBounds = (markers) => {
  let bounds = {
    sw: { lat: 99, lng: 99 },
    ne: { lat: -99, lng: -99 },
  }

  markers.forEach(({ lat, lng }) => {
      if (lat > bounds.ne.lat) bounds.ne.lat = lat
      if (lat < bounds.sw.lat) bounds.sw.lat = lat
      if (lng < bounds.sw.lng) bounds.sw.lng = lng
      if (lng > bounds.ne.lng) bounds.ne.lng = lng
      return false
    })

  return bounds
}

export const getMapPadding = (isMobile, isExpanded) => {
  if (isMobile) {
    return {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    }
  }

  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: MAP_DESKTOP_PADDING_LEFT,
  }
}

export const getCatSlugForMarker = (activeCatSlug, markerCatSlugs) => {
  if (activeCatSlug && markerCatSlugs.includes(activeCatSlug)) return activeCatSlug
  return markerCatSlugs[0]
}
