import { pathOr } from 'ramda'
import axios from 'axios'
import { createSelector } from 'reselect'
import { SERVER_URL } from 'admin-modules/build/constants'
import { getLanguage } from '../../features/urlParams'

export const FETCH_STATIC_PAGE = 'FETCH_STATIC_PAGE'
export const STATIC_PAGE_LOADING = 'STATIC_PAGE_LOADING'
export const STATIC_PAGE_SUCCESS = 'STATIC_PAGE_SUCCESS'
export const STATIC_PAGE_FAIL = 'STATIC_PAGE_FAIL'

export const staticPageLoading = () => ({
  type: STATIC_PAGE_LOADING,
})

export const staticPageSuccess = payload => ({
  type: STATIC_PAGE_SUCCESS,
  payload,
})

export const staticPageFail = payload => ({
  type: STATIC_PAGE_FAIL,
  payload,
})

export const fetchStaticPage = (slug, language) => (dispatch, getState) => {
  dispatch(staticPageLoading())
  const state = getState()
  const url = language === 'sk'
    ? `${SERVER_URL}/api/pages/${slug}`
    : `${SERVER_URL}/api/en/pages/${slug}`

  axios
    .get(url)
    .then((response) => {
      const content = language === 'sk'
        ? pathOr('',['data', 0], response)
        : pathOr('',['data', 0, 'en'], response)
      
      dispatch(staticPageSuccess({
        content,
        slug,
      }))
    })
    .catch((error) => {
      dispatch(staticPageFail({ error }))
    })
}

const initialState = {}

export const staticPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_STATIC_PAGE:
      return {
        ...state,
        [payload.slug]: {
          isLoading: true,
          error: null,
        }
      }
    case STATIC_PAGE_SUCCESS:
      return {
        ...state,
        [payload.slug]: {
          isLoading: false,
          error: null,
          ...payload.content,
        }
      }
    case STATIC_PAGE_FAIL:
      return {
        ...state,
        [payload.slug]: {
          isLoading: false,
          error: payload.error,
        }
      }
    default:
      return state
  }
}

export const getStaticPagesState = state => pathOr({}, ['STATIC_PAGES'], state)

export const getStaticPageLoading = slug => createSelector(
  getStaticPagesState,
  (state) => pathOr(false, [slug], state)
)

export const getStaticPageContent = slug => createSelector(
  getStaticPagesState,
  (state) => pathOr(null, [slug], state),
)
