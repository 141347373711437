import React, { Component } from 'react'
import PT from 'prop-types'
import './ShareBtn.sass'

class ShareBtn extends Component {
  componentDidMount() {
    this.parse()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.to !== this.props.to) {
      this.parse()
    }
  }

  parse() {
    if (global.FB && global.FB.XFBML && global.FB.XFBML.parse) {
      global.FB.XFBML.parse()
    }
  }

  render() {
    return (
      <div
        className="fb-share-button"
        data-href={this.props.to}
        data-layout="box_count"
        data-size="small"
      >
        <a
          target="_blank"
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
          className="fb-xfbml-parse-ignore"
        >
          Share
        </a>
      </div>
    )
  }
}

ShareBtn.propTypes = {
  to: PT.string.isRequired,
}

export default ShareBtn
