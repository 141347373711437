import React from 'react'
import { hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { initAnalytics } from 'bknd-modules/build/GoogleAnalytics/init'
import { isProduction } from 'bknd-modules/build/helpers'
import * as Sentry from '@sentry/browser'
import { configureStore, getHistory } from './store'
import App from './App'
import initFb from './initFb'

if (isProduction) {
  Sentry.init({dsn: "https://ff51bf3709a54130abe6e18414b23852@sentry.io/1523308"})
}

window.global = window

initFb()
initAnalytics()

const store = configureStore()
const history = getHistory()

hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
