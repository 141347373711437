import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory, createMemoryHistory } from 'history'
import reducer from './reducers'
import { isServer } from './utils'

export const getHistory = (url = '/') => !isServer
    ? createBrowserHistory()
    : createMemoryHistory(url)

export const configureStore = (url, initialData) => {
  const preloadedData = initialData
    ? initialData
    : global.__initialData__

  const reduxDevTools = !isServer && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  const history = getHistory(url)

  const middlewares = [
    routerMiddleware(history),
    thunk,
  ]

  const enhanced = reduxDevTools
    ? compose(
        applyMiddleware(...middlewares),
        reduxDevTools,
      )
    : applyMiddleware(...middlewares)

  const store = createStore(
    reducer(history),
    preloadedData,
    enhanced,
  )

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(reducer)
      });
    }
  }

  return store;
};
