/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { toggleExpand } from '../Layout/actions';
import { getIsLayoutExpanded } from '../Layout/selectors';
import './_ExpandBtn.sass'

export const ExpandBtn = ({ expanded, onToggle }) => (
  <a
    href=""
    className={cx('expand-btn', { expanded: !expanded })} // todo: reverse expanded css class
    onClick={(e) => {
      e.preventDefault()
      onToggle()
      window.scrollTo(0, 0)
    }}
  >
    Expand
  </a>
)

ExpandBtn.propTypes = {
  expanded: PT.bool,
  onToggle: PT.func.isRequired,
}

ExpandBtn.defaultProps = {
  expanded: false,
}

const mapStateToProps = (state) => ({
  expanded: getIsLayoutExpanded(state),
})

const mapDispatchToProps = {
  onToggle: toggleExpand,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandBtn)
