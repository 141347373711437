import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { getLanguage } from './urlParams';

export const RECEIVE_TEXTS = 'RECEIVE_TEXTS'

export const receiveTexts = payload => ({
  type: RECEIVE_TEXTS,
  payload,
})

export const textsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case RECEIVE_TEXTS:
      return {
        ...state,
        ...payload,
      }
  
    default:
      return state
  }
}

export const getTexts = state => state.TEXTS

export const getTextBySlug = slug => createSelector(
  getTexts,
  getLanguage,
  (texts, language) => {
    const withLanguageSlug = language === 'sk'
      ? slug
      : `${slug}-en`
    const found = texts.find(t => t.slug === withLanguageSlug)
    return pathOr('', ['text'], found)
  }
)
