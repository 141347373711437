export const MAP_KEY = 'AIzaSyAbXTG60zQvykZgKRmle13QmhoMgOT0_kc'
export const MAP_DEFAULT_CENTER = global.innerWidth && global.innerWidth < 768
  ? {
    lat: 48.148347897560114,
    lng: 17.107180435375454,
  }
  : {
      lat: 48.14914962369414,
      lng: 17.08529360981393,
    }
export const MAP_DEFAULT_ZOOM = 14
export const SET_MAP_CENTER = 'SET_MAP_CENTER'
export const SET_CROSSHAIR_CENTER = 'SET_CROSSHAIR_CENTER'
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM'
export const CLOSE_BALLOON = 'CLOSE_BALLOON'

export const PLACE_MOUSE_OVER = 'PLACE_MOUSE_OVER'
export const PLACE_MOUSE_OUT = 'PLACE_MOUSE_OUT'
