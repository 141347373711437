export default () => `
<!-- Begin MailChimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{clear:left; font:14px; width:400px;}
    #mc_embed_signup .button {clear:both; background-color: #F00; border: 0 none; border-radius:4px; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px;  height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding:0; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: auto;}
    #mc_embed_signup .button:hover {background-color:#bd0000;}
	/* Add your own MailChimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="//mpba.us11.list-manage.com/subscribe/post?u=9712627f0a5376d45adaf084d&amp;id=68a43f1929" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	<label for="mce-EMAIL">Ak chcete dostávať upozornenia o budúcich vlastivedných, zaraďte sa do vychádzkového mailinglistu</label>
	<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="tutok e-mail prosím" required>
    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_9712627f0a5376d45adaf084d_68a43f1929" tabindex="-1" value=""></div>
    <div class="clear"><input type="submit" value="Prihlásiť" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
    </div>
</form>
</div>
<!--End mc_embed_signup-->
`