import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import PT from 'prop-types'
import {
  placesProp,
  placesDefault,
  languageProp,
  languageDefault,
} from '../../propTypes'
import PlacesList from './PlacesList'

const PlaceCategory = ({ language, opened, slug, title, items }) => {
  const base = (language === 'sk') ? '/' : '/en/'
  const end = opened ? '' : slug
  const link = base + end

  return (
    <li className={cx({ opened })}>
      <Link
        to={link}
        className="category-title"
      >
        {title}
        <span>{items.length}</span>
      </Link>
      <PlacesList
        items={items}
        language={language}
        categorySlug={slug}
      />
    </li>
  )
}

PlaceCategory.propTypes = {
  items: placesProp,
  language: languageProp,
  opened: PT.bool,
  slug: PT.string.isRequired,
  title: PT.string.isRequired,
}

PlaceCategory.defaultProps = {
  items: placesDefault,
  language: languageDefault,
  opened: false,
}


export default PlaceCategory
