import PT from 'prop-types'

export const languageProp = PT.oneOf(['sk', 'en'])
export const languageDefault = 'sk'

export const placeProp = PT.shape({
  title: PT.string.isRequired,
  slug: PT.string.isRequired,
})

export const placesProp = PT.arrayOf(placeProp)
export const placesDefault = []

export const placesByCategoriesDefault = {}

export const placesByCategoriesProp = PT.shape({
  place: PT.shape({
    href: PT.string.isRequired,
    slug: PT.string.isRequired,
  }),
})

export const routerShape = PT.shape({
  location: PT.shape({
    pathname: PT.string.isRequired,
  }),
})

export const markerShape = PT.shape({
  id: PT.string.isRequired,
  lat: PT.number.isRequired,
  lng: PT.number.isRequired,
  title: PT.string.isRequired,
  text: PT.string.isRequired,
  image: PT.string,
  imgRatio: PT.number,
  categorySlug: PT.arrayOf(PT.string.isRequired).isRequired,
  slug: PT.string.isRequired,
})

export const matchProp = PT.shape({
  params: PT.shape({
    language: PT.string,
    categorySlug: PT.string,
    slug: PT.string,
  }),
  path: PT.string.isRequired,
})

export const imagesProp = PT.arrayOf(
  PT.oneOfType([
    PT.string,
    PT.shape({
      id: PT.string.isRequired,
      width: PT.number.isRequired,
      height: PT.number.isRequired,
      original: PT.string.isRequired,
      thumbs: PT.arrayOf(PT.shape({
        name: PT.string.isRequired,
        width: PT.number.isRequired,
        height: PT.number.isRequired,
      })),
    })
  ])
)
