import React from 'react'
import PT from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'
import PlacesByCategories from '../Places/PlacesByCategories'
import PlaceDetail from './PlaceDetail'
import { getSlugs } from '../../features/urlParams'
import { getTextBySlug } from '../../features/texts'
import { getIsMobileLayout, getIsAppMounted } from '../Layout/selectors'
import LatestPlaces from './LatestPlaces'
import LastUpdate from '../LastUpdate/LastUpdate'
import "./_home.sass"

const HomeContent = ({ homeText, placeSlug, isMobile, appDidMount }) => {
  const elem = <h1>{homeText}</h1>
  const h1 = !appDidMount
    ? elem
    : !isMobile && elem

  return (
    <div className={cx('home-container', {
      'places-opened': placeSlug,
    })}>
      <div className="home-wrapper clearfix">
        <div className="welcome slide">
          <div className="welcome-wrap">
            {h1}
            <PlacesByCategories />
            <LatestPlaces />

            {!isMobile && <LastUpdate />}
          </div>
        </div>
        <div className="place-detail slide">
          <PlaceDetail />
        </div>
      </div>
    </div>
  )
}

HomeContent.propTypes = {
  homeText: PT.string.isRequired,
  placeSlug: PT.string,
}

HomeContent.defaultProps = {
  placeSlug: '',
}

const mapStateToProps = (state) => ({
  appDidMount: getIsAppMounted(state),
  isMobile: getIsMobileLayout(state),
  homeText: getTextBySlug('home-text')(state),
  placeSlug: getSlugs(state).placeSlug,
})

export default connect(mapStateToProps)(HomeContent)
