import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import PT from 'prop-types'
import { connect } from 'react-redux'
import Balloon from './Balloon'
import { getIsMobileLayout } from '../Layout/selectors'
import { collapseLayout } from '../Layout/actions'
import { getLanguage, getSlugs } from '../../features/urlParams'
import { getClosedBalloons, getHoveredSlug } from './selectors'
import { placeMouseOver, placeMouseOut } from './actions'

// slug is unique slug used for closing balloon (has to be unique)
// placeSlug is original slug of place, used for link
const Marker = ({
  activeSlugs,
  categorySlug,
  closedSlugs,
  collapseLayout,
  isMobile,
  placeSlug,
  placeMouseOut,
  placeMouseOver,
  hoveredSlug,
  slug,
  state,
  imageUrl,
  title,
  text,
  language,
}) => {
  const [isMobileBalloonShown, setIsMobileBalloonShown] = useState(false)
  const langUrlPrefix = language === 'en' ? '/en' : ''
  const isClosed = closedSlugs.includes(slug)
  const isHovered = placeSlug === hoveredSlug || slug === hoveredSlug

  const showBalloon = (!isClosed && state && state !== 'highlighted')
    || isHovered || isMobileBalloonShown

  return (
    <Link
      to={`${langUrlPrefix}/${categorySlug}/${placeSlug}`}
      className={cx('marker', categorySlug, slug, {
        active: state === 'active',
        highlighted: state === 'highlighted',
        hovered: state === 'hovered',
        inactive: activeSlugs.placeSlug && state !== 'active',
      })}
      onMouseEnter={() => {
        placeMouseOver(slug)
      }}
      onMouseLeave={placeMouseOut}
      onClick={(e) => {
        if (!isMobile) return;

        if (isMobileBalloonShown) {
          setIsMobileBalloonShown(false)
          collapseLayout()
          return
        }

        e.preventDefault()
        setIsMobileBalloonShown(true)
      }}
    >
      {showBalloon && (
        <Balloon
          imageUrl={(isHovered && state !== 'active' || isMobile) ? imageUrl : ''}
          state={state}
          text={text}
          title={title}
          slug={slug}
        />
      )}
    </Link>
  )
}

Marker.propTypes = {
  activeSlugs: PT.object,
  categorySlug: PT.string,
  collapseLayout: PT.func.isRequired,
  hoveredSlug: PT.string,
  imageUrl: PT.string,
  isMobile: PT.bool.isRequired,
  language: PT.oneOf(['sk', 'en']),
  placeMouseOut: PT.func.isRequired,
  placeMouseOver: PT.func.isRequired,
  placeSlug: PT.string.isRequired,
  slug: PT.string.isRequired,
  state: PT.string,
  text: PT.string,
  title: PT.string.isRequired,
}

Marker.defaultProps = {
  activeSlugs: {},
  categorySlug: '',
  hoveredSlug: '',
  imageUrl: '',
  language: 'sk',
  text: '',
}

const mapStateToProps = (state) => ({
  activeSlugs: getSlugs(state),
  closedSlugs: getClosedBalloons(state),
  isMobile: getIsMobileLayout(state),
  hoveredSlug: getHoveredSlug(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  collapseLayout,
  placeMouseOver,
  placeMouseOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Marker)
