import {
  SET_MAP_CENTER,
  SET_MAP_ZOOM,
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_ZOOM,
  CLOSE_BALLOON,
  SET_CROSSHAIR_CENTER,
  PLACE_MOUSE_OVER,
  PLACE_MOUSE_OUT,
} from './constants'
import { UPDATE_SLUGS } from '../../features/urlParams'

export const initialState = {
  center: MAP_DEFAULT_CENTER,
  zoom: MAP_DEFAULT_ZOOM,
  showCrosshair: false,
  closedBalloons: [],
  hoveredSlug: '',
}

export const mapReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MAP_CENTER:
    case SET_MAP_ZOOM:
    case SET_CROSSHAIR_CENTER:
      return {
        ...state,
        ...payload,
      }

    case CLOSE_BALLOON:
      return {
        ...state,
        closedBalloons: [
          ...state.closedBalloons,
          payload.slug,
        ]
      }

    case PLACE_MOUSE_OVER:
      return {
        ...state,
        hoveredSlug: payload.slug,
      }

    case PLACE_MOUSE_OUT:
      return {
        ...state,
        hoveredSlug: '',
      }

    case UPDATE_SLUGS:
      return {
        ...state,
        closedBalloons: [],
      }

    default:
      return state
  }
}
