import {
  APP_DID_MOUNT,
  COLLAPSE_LAYOUT,
  LAYOUT_RESIZED,
  MENU_TOGGLED,
  MENU_CLOSED,
  EXPAND_TOGGLED,
  MAP_LEFT_EDGE_CHANGED,
  SEARCH_TOGGLED,
} from './constants';
import { isServer } from '../../utils'

const initialState = {
  width: isServer ? 0 : window.innerWidth,
  height: isServer ? 0 : window.innerHeight,
  menuOpened: false,
  expanded: false,
  searchOpened: false,
}

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_RESIZED: {
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      }
    }

    case MENU_TOGGLED:
      return {
        ...state,
        menuOpened: !state.menuOpened,
      }

    case MENU_CLOSED:
      return {
        ...state,
        menuOpened: false,
      }

    case EXPAND_TOGGLED:
      return {
        ...state,
        expanded: !state.expanded,
      }

    case COLLAPSE_LAYOUT:
      return {
        ...state,
        expanded: false,
      }

    case MAP_LEFT_EDGE_CHANGED:
     return {
       ...state,
       mapLeftEdge: action.payload.x,
     }

    case APP_DID_MOUNT:
      return {
        ...state,
        appDidMount: true,
      }

    case SEARCH_TOGGLED:
      return {
        ...state,
        searchOpened: !state.searchOpened,
      }
    
    default:
      return state
  }
}
