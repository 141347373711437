import React from 'react'
import PT from 'prop-types'
import { Helmet } from 'react-helmet'
import Icons from './Icons'

const PageHead = ({
  ogDesc,
  ogImage,
  ogTitle,
  title,
}) => (
  <Helmet>
    {process.env.REACT_APP_NOFOLLOW && (
      <meta name="robots" content="noindex, nofollow" />
    )}

    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <title>{title}</title>
    <meta name="description" content={ogDesc} />
    <meta property="og:url" content={global.location.href} />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={ogTitle} />
    <meta property="og:description" content={ogDesc} />
    <meta property="og:image" content={ogImage} />

    <Icons />
  </Helmet>
)

PageHead.propTypes = {
  ogDesc: PT.string,
  ogImage: PT.string,
  ogTitle: PT.string,
  title: PT.string,
}

PageHead.defaultProps = {
  ogDesc: 'M_P_ BA je mapa obľúbených miest obyvateľov Bratislavy s ich autentickými odporúčaniami a subjektívnymi názormi. Od kaviarní a služieb po zaujímavé, až bizarné miesta.',
  ogImage: '/metaimage_mpba.jpg',
  ogTitle: 'M_P_ BA',
  title: 'M_P_ BA - mapa pekných miest Bratislavy',
}

export default PageHead
