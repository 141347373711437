import axios from 'axios'
import { pathOr } from 'ramda'
import { SERVER_URL } from 'admin-modules/build/constants'

export const FETCH_PLACE_DATA = 'FETCH_PLACE_DATA'

export const RECEIVE_PLACE_DATA = 'RECEIVE_PLACE_DATA'

export function receivePlaceData(content, placeSlug) {
  return {
    type: RECEIVE_PLACE_DATA,
    payload: {
      content,
      placeSlug,
    },
  }
}

export const fetchPlaceData = (language, placeSlug) => (dispatch) => {
  const fragment = language === 'sk' ? 'single' : 'single-en'
  const url = `${SERVER_URL}/api/items/${fragment}/${placeSlug}`
  axios
    .get(url)
    .then((response) => {
      const content = language === 'sk'
        ? pathOr('',['data', 0, 'content'], response)
        : pathOr('',['data', 0, 'en', 'content'], response)
      dispatch(receivePlaceData(content, placeSlug))
    })
    .catch(error => console.log(error))
}
