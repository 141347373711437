import { RECEIVE_PLACE_DATA } from '../Home/duck'

export const SET_PLACES = 'SET_PLACES'

export const setPlaces = (places) => ({
  type: SET_PLACES,
  payload: {
    places,
  }
})

export const placesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_PLACES:
      return action.payload.places

    case RECEIVE_PLACE_DATA: {
      const { placeSlug, content } = action.payload
      const index = state.findIndex(place => place.slug === placeSlug || place.en.slug === placeSlug)

      if (index < 0) return

      const newState = [...state]
      newState[index] = { ...state[index], content }
      return newState
    }

    default:
      return state
  }
}

