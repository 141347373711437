export default {
  sk: [
    { href: '/', text: 'Mapa' },
    { href: '/mam-tip', text: 'Mám tip!' },
    // { href: '/chcem-mapu', text: 'Chcem Mapu' },
    { href: '/page/co-to-je', text: 'Čo to je?' },
    { href: '/page/vychadzky', text: 'Vychádzky' },
    // { href: '/linky', text: 'Linky' },
    // { href: '/vychadzky', text: 'Vychádzky' },
  ],
  en: [
    { href: '/en/', text: 'Map' },
    { href: '/en/got-tip', text: 'I got a tip!' },
    // { href: '/en/want-map', text: 'I want a map!' },
    { href: '/en/page/what-is-it', text: 'What is it?' },
    { href: '/en/page/tours', text: 'Tours' },
    // { href: '/en/links', text: 'Links' },
  ],
}
