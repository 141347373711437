import React, { Component, Fragment } from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import debounce from 'javascript-debounce'
import { withAnalytics } from 'bknd-modules/build/GoogleAnalytics/withAnalytics'
import Header from '../Header/Header'
import PageContainer from './PageContainer'
import { setAppDidMount, layoutResized } from './actions'
import { languageProp, matchProp } from '../../propTypes'
import naviItems from '../../naviItems'
import { updateSlugs, changeLanguage, getLanguage } from '../../features/urlParams'
import { getForbiddenSlugs } from './helpers'
import MPBAMap from '../map/MPBAMap'
import CookieBar from '../CookieBar/CookieBar'
import Search from '../Search/Search'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.debouncedResize = debounce(this.handleResize, 100)
  }

  updateLanguage = () => {
    const { language, match, onLanguageChange } = this.props
    const newLanguage = match.path.startsWith('/en') ? 'en' : 'sk'
    if (newLanguage !== language) {
      onLanguageChange(newLanguage)
    }
  }

  updateSlugs = (prevSlugs = {}) => {
    const { onSlugsChange } = this.props
    const { categorySlug, placeSlug } = this.props.match.params

    // make sure that categorySlug is not updated by url from other static pages
    const forbidden = getForbiddenSlugs(naviItems)
    if (forbidden.includes(categorySlug)) {
      onSlugsChange('', '')
      return
    }

    if (categorySlug !== prevSlugs.categorySlug || placeSlug !== prevSlugs.placeSlug) {
      onSlugsChange(categorySlug, placeSlug)
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.debouncedResize)
    this.debouncedResize()
    this.updateLanguage()
    this.updateSlugs()
    this.props.setAppDidMount()
  }

  componentDidUpdate(prevProps) {
    this.updateLanguage()
    this.updateSlugs(prevProps.match.params)
  }

  handleResize = () => {
    this.props.onLayout()
  }

  render() {
    const { language } = this.props

    return (
      <Fragment>
        <Header naviItems={naviItems[language]} />
        <Search />
        <CookieBar />
        <MPBAMap />
        <PageContainer />
      </Fragment>
    )
  }
}

Layout.propTypes = {
  setAppDidMount: PT.func.isRequired,
  language: languageProp,
  onLayout: PT.func.isRequired,
  onLanguageChange: PT.func.isRequired,
  match: matchProp.isRequired,
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

const mapDispatchToProps = {
  setAppDidMount,
  onLayout: layoutResized,
  onLanguageChange: changeLanguage,
  onSlugsChange: updateSlugs,
}

export default connect(mapStateToProps, mapDispatchToProps)(withAnalytics(Layout))