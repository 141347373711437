import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { getPlacesByLanguage } from '../Places/selectors'
import { getSlugs, getLanguage } from '../../features/urlParams'
import { getMarkerState } from './helpers'
import { getImageUrl } from '../../helpers';

const empty = {}

export const getMapState = state => pathOr(empty, ['MAP'], state)

export const getMapCenter = createSelector(
  state => pathOr(empty, ['center'], getMapState(state)),
  center => center,
)

export const getCrosshairCenter = (state) => pathOr(empty, ['crosshairCenter'], getMapState(state))

export const getMapZoom = state => pathOr(empty, ['zoom'], getMapState(state))

export const getHoveredSlug = state => pathOr(null, ['hoveredSlug'], getMapState(state))

export const getClosedBalloons = createSelector(
  state => pathOr([], ['closedBalloons'], getMapState(state)),
  balloons => balloons,
)

export const getMarkers = createSelector(
  getPlacesByLanguage,
  getLanguage,
  getSlugs,
  (places, language, slugs) => {
    let markers = {}

    places
      // show only active markers
      .filter(place => {
        if (slugs.placeSlug) {
          // return slugs.placeSlug === place.slug
          return true
        }
        if (slugs.categorySlug && !slugs.placeSlug) {
          return place.categories.some(p => p.slug === slugs.categorySlug)
        }
        return true
      })
      .forEach((place) => {
        const imageUrl = place.images && place.images[0]
          ? getImageUrl(place.images[0])
          : ''
        const common = {
          title: place.title,
          categorySlugs: place.categories.map(cat => cat.slug),
          imageUrl,
          language,
          state: getMarkerState(slugs, place.categories, place.slug),
        }
        
        place.locations && place.locations.forEach((location, index) => {
          if (!location.lat || !location.lng) {
            // TODO: report error here'
            return
          }

          const uniqueSlug = index === 0
            ? place.slug
            : `${place.slug}${index + 1}`
          
          markers[uniqueSlug] = {
            ...common,
            placeSlug: place.slug,
            slug: uniqueSlug,
            id: `${location.lat}/${location.lng}`,
            lat: location.lat,
            lng: location.lng,
            text: location.desc,
          }
        })
      })

    return markers
  }
)

export const getShowCrosshair = state => {
  const pathname = pathOr(false, ['location', 'pathname'], global)
  return pathname === '/mam-tip' || pathname === '/en/got-tip'
}
