import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { pathOr } from 'ramda'
import { getLatestPlaces, latestPlacesPropType } from '../../features/latestPlaces'
import { getLanguage } from '../../features/urlParams'
import { languageProp } from '../../propTypes'

const getLink = (language, place) => {
  const isSk = language === 'sk'
  const start = isSk ? '/' : '/en/'
  const categorySlug = isSk
    ? pathOr('', [0, 'slug'], place.categories)
    : pathOr('', [0, 'en', 'slug'], place.categories)
  const placeSlug = isSk
    ? pathOr('', ['slug'], place)
    : pathOr('', ['en', 'slug'], place)

  return `${start}${categorySlug}/${placeSlug}`
}

const LatestPlaces = ({ language, latestPlaces }) => {
  const headline = language === 'sk'
    ? 'Najnovšie prírastky'
    : 'Latest additions'

  return (
    <div className="latest-places">
      {latestPlaces.length > 0 && <h3>{headline}</h3>}
      <ul>
        {latestPlaces.map(p => (
          <li key={p._id}>
            <Link
              to={getLink(language, p)}
            >
              {p.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

LatestPlaces.propTypes = {
  language: languageProp,
  latestPlaces: latestPlacesPropType,
}

LatestPlaces.defaultProps = {
  latestPlaces: [],
}

export default connect(
  state => ({
    language: getLanguage(state),
    latestPlaces: getLatestPlaces(state),
  })
)(LatestPlaces)
