import React from 'react'
import PT from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { values } from 'ramda'
import mapStyles from './fancyMapStyles.json'
import Marker from './Marker'
import { MAP_KEY } from './constants'
import { MOBILE_MAP_HEIGHT } from '../../constants'
import {
  getMapStyles,
  mapDistanceToMouse,
  getBounds,
  getMapPadding,
  getCatSlugForMarker,
} from './helpers'
import { markerShape } from './propTypes'
import { getMapCenter, getMapZoom, getMarkers, getShowCrosshair } from './selectors'
import {
  getIsAppMounted,
  getIsMobileLayout,
  getIsLayoutExpanded,
  getLayoutHeight,
  getLayoutWidth
} from '../Layout/selectors';
import { getSlugs } from '../../features/urlParams'
import { MAP_DESKTOP_PADDING_LEFT } from '../../constants'
import { setZoom, setCenter, setCrosshairCenter } from './actions';
import './mpbamap.sass'

export class MPBAMap extends React.Component {
  state = {
    mapStyles: {},
  }

  componentDidMount() {
    this.setStyles()
  }

  componentDidUpdate(prevProps) {
    const { isLayoutExpanded, slugs, isMobileLayout, viewportHeight, viewportWidth } = this.props
    const categoryChanged = prevProps.slugs.categorySlug !== slugs.categorySlug
    const placeChanged = prevProps.slugs.placeSlug !== slugs.placeSlug
    const expandedChanged = prevProps.isLayoutExpanded !== isLayoutExpanded
    const isMobileChanged = prevProps.isMobileLayout !== isMobileLayout
    const sizeChanged = prevProps.viewportHeight !== viewportHeight || prevProps.viewportWidth !== viewportWidth
    
    if (categoryChanged || placeChanged || expandedChanged) {
      setTimeout(this.fit, 500)
    }

    if (isMobileChanged || expandedChanged || sizeChanged) {
      this.setStyles()
    }

    // on center change, update crosshair center
    const { crosshair, center } = this.props
    const centerChanged = prevProps.center.lat !== center.lat || prevProps.center.lng !== center.lng
    if (crosshair && centerChanged) {
      this.calculateOffset()
    }
  }

  setStyles = () => {
    const { isLayoutExpanded, isMobileLayout, viewportHeight } = this.props
    this.setState({
      mapStyles: getMapStyles(isLayoutExpanded, isMobileLayout, viewportHeight),
    })
  }

  center(pos) {
    if (!pos || !pos.lat || !pos.lng) {
      return
    }
    const center = new this.maps.LatLng(pos.lat, pos.lng)
    
    if (this.props.zoom < 14) {
      this.map.setZoom(14)
    }
    this.map.panTo(center)
    
    if (!this.props.isMobileLayout) {
      this.map.panBy(- MAP_DESKTOP_PADDING_LEFT / 2, 0)
    } else {
      const offset = MOBILE_MAP_HEIGHT * window.innerHeight / 2 * 0.4
      this.map.panBy(0, -offset)
    }
  }

  // map on desktop is offset by 300px, what is the offset in lng degrees?
  calculateOffset = () => {
    if (!this.map) return

    const { isMobileLayout } = this.props

    if (!isMobileLayout) {
      this.map.panBy(300,0)
    }
    const lat = this.map.getCenter().lat()
    const lng = this.map.getCenter().lng()
    if (!isMobileLayout) {
      this.map.panBy(-300,0)
    }
    this.props.setCrosshairCenter({ lat, lng })
  }

  fit = () => {
    if (!this.maps) return

    const { slugs } = this.props

    if (!slugs.categorySlug && !slugs.placeSlug) return
    
    if (slugs.categorySlug && !slugs.placeSlug) {
      this.fitCategory()
      return
    }
    
    if (slugs.placeSlug) {
      const active = this.props.markers.filter(m => m.state === 'active')
      if (active.length > 1) {
        this.fitBounds(active)
        return
      }
      this.center(active[0])
    }
  }

  fitBounds(markers) {
    const { sw, ne } = getBounds(markers)
    const gsw = new this.maps.LatLng(sw.lat, sw.lng)
    const gne = new this.maps.LatLng(ne.lat, ne.lng)
    const bounds = new this.maps.LatLngBounds(gsw, gne)
    const { isMobileLayout, isLayoutExpanded } = this.props
    const padding = getMapPadding(isMobileLayout, isLayoutExpanded)
    this.map.fitBounds(bounds, padding)
  }

  fitCategory() {
    const highlighted = this.props.markers.filter(m => m.state === 'highlighted')
    this.fitBounds(highlighted)
  }

  handleMapChange = ({ center, zoom }) => {
    const { props } = this
    const centerChanged = center.lat !== props.center.lat || center.lng !== props.center.lng

    if (centerChanged) {
      props.setCenter(center)
    }

    if (zoom !== props.zoom) {
      props.setZoom(zoom)
    }
  }

  render() {
    const { appDidMount, crosshair, isMobileLayout, markers, center, zoom } = this.props

    return (
      <div
        className={cx('map-container', { 'crosshair-map': crosshair })}
        style={this.state.mapStyles}
      >
        <GoogleMapReact
          center={center}
          zoom={zoom}
          bootstrapURLKeys={{
            key: MAP_KEY,
          }}
          options={{
            styles: mapStyles,
            gestureHandling: 'greedy',
            clickableIcons: false,
            zoomControl: !isMobileLayout,
            fullscreenControl: !isMobileLayout,
            mapTypeControl: true,
            controlSize: isMobileLayout ? 22 : 25,
          }}
          hoverDistance={10}
          distanceToMouse={mapDistanceToMouse}
          onChange={this.handleMapChange}
          onGoogleApiLoaded={({ map, maps }) => {
            this.map = map
            this.maps = maps
            this.fit()
            this.calculateOffset()
          }}
          yesIWantToUseGoogleMapApiInternals
          resetBoundsOnResize
        >
          {appDidMount && !crosshair && markers.map((marker) => {
            return (
              <Marker
                key={marker.id}
                categorySlug={getCatSlugForMarker(this.props.slugs.categorySlug, marker.categorySlugs)}
                imageUrl={marker.imageUrl}
                lat={marker.lat|| 0}
                lng={marker.lng || 0}
                placeSlug={marker.placeSlug}
                slug={marker.slug}
                state={marker.state}
                text={marker.text}
                title={marker.title}
              />
            )
          })}
        </GoogleMapReact>
      </div>
    )
  }
}

MPBAMap.propTypes = {
  crosshair: PT.bool,
  isLayoutExpanded: PT.bool,
  isMobileLayout: PT.bool.isRequired,
  setCenter: PT.func.isRequired,
  setCrosshairCenter: PT.func.isRequired,
  setZoom: PT.func.isRequired,
  markers: PT.arrayOf(markerShape),
  center: PT.shape({
    lat: PT.number.isRequired,
    lng: PT.number.isRequired,
  }).isRequired,
  viewportHeight: PT.number.isRequired,
  viewportWidth: PT.number.isRequired,
  zoom: PT.number.isRequired,
}

MPBAMap.defaultProps = {
  crosshair: false,
  markers: [],
  isLayoutExpanded: true,
}

const mapStateToProps = (state) => ({
  appDidMount: getIsAppMounted(state),
  center: getMapCenter(state),
  zoom: getMapZoom(state),
  crosshair: getShowCrosshair(state),
  isLayoutExpanded: getIsLayoutExpanded(state),
  markers: values(getMarkers(state)),
  isMobileLayout: getIsMobileLayout(state),
  slugs: getSlugs(state),
  viewportWidth: getLayoutWidth(state),
  viewportHeight: getLayoutHeight(state),
})

const mapDispatchToProps = {
  setZoom,
  setCenter,
  setCrosshairCenter,
}

export default connect(mapStateToProps, mapDispatchToProps)(MPBAMap)
