import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { getIsMobileLayout } from '../Layout/selectors'
import { getLastMapUpdate } from '../../features/latestPlaces'

const LastUpdate = ({ lastUpdate, isMobile }) => {
  return (
    <p
      className={cx('update', {
        mobile: isMobile,
      })}
    >
      Posledný update: <span>{lastUpdate}</span>
    </p>
  )
}

LastUpdate.propTypes = {
  lastUpdate: PT.string.isRequired,
  isMobile: PT.bool.isRequired,
}

export default connect(
  state => ({
    isMobile: getIsMobileLayout(state),
    lastUpdate: getLastMapUpdate(state),
  })
)(LastUpdate)
