import { pathOr } from 'ramda'
import naviItems from '../../naviItems'

export const getMapSlugTranslations = (
  translationMap,
  categorySlug,
  placeSlug,
  language,
  translationLang,
) => {
  const category = categorySlug && translationMap.categories.find(c => c[language] === categorySlug)
  const categorySlugTrans = category ? category[translationLang] : null
  const foundPlaceSlug = placeSlug && translationMap.places.find(p => p[language] === placeSlug)
  const placeSlugTrans = placeSlug && foundPlaceSlug && foundPlaceSlug[translationLang]

  return {
    categorySlugTrans,
    placeSlugTrans,
  }
}

export const getTranslationLink = (pathname, language, translationMap, categorySlug, placeSlug) => {
  const isMapPage = Boolean(categorySlug)
  const translationLang = language === 'sk' ? 'en' : 'sk'

  if (isMapPage) {
    const { categorySlugTrans, placeSlugTrans } = getMapSlugTranslations(
      translationMap,
      categorySlug,
      placeSlug,
      language,
      translationLang,
    )

    if (!categorySlugTrans) return '/'

    // english urls always start with /en
    let url = translationLang === 'en' ? '/en' : ''
    url += `/${categorySlugTrans}`
    url += placeSlugTrans ? `/${placeSlugTrans}` : ''
    return url
  }

  // other pages translations
  if (!language) {
    return 'sk'
  }

  const index = naviItems[language].findIndex(item => item.href === pathname)
  return pathOr('', [translationLang, index, 'href'], naviItems)
}