import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { layoutReducer } from '../containers/Layout/reducer'
import { placesReducer } from '../containers/Places/places'
import { categoriesReducer } from '../features/categories'
import { urlSlugsReducer } from '../features/urlParams'
import { textsReducer } from '../features/texts'
import { mapReducer } from '../containers/map/reducer'
import { staticPageReducer } from '../containers/StaticPage/duck'

export default (history) => combineReducers({
  router: connectRouter(history),
  CATEGORIES: categoriesReducer,
  LAYOUT: layoutReducer,
  PLACES: placesReducer,
  URL_PARAMS: urlSlugsReducer,
  MAP: mapReducer,
  STATIC_PAGES: staticPageReducer,
  TEXTS: textsReducer,
  LAST_MAP_UPDATE: (state = null) => state,
})
