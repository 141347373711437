import React from 'react'
import PT from 'prop-types'

const Arrow = ({ to, onClick }) => {
  return (
    <button
      type="button"
      className={`image-carousel-button icon icon-arrow-${to} ${to}`}
      onClick={onClick}
    />
  )
}

Arrow.propTypes = {
  onClick: PT.func,
  to: PT.oneOf(['left', 'right']),
}

export default Arrow
