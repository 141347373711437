import React, { Component } from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import './search-results.sass'

class SearchResults extends Component {
  render() {
    const { query, results } = this.props
    const decoded = decodeURIComponent(query)

    return (
      <div className="search-results">
        <h2>"{decoded}"</h2>
        
        {results.map(r => (
          <div className="result" key={r.id}>
            <h4>{r.title}</h4>
            <p className="location">{r.location}</p>
            <p className="text">{r.text}</p>
          </div>
        ))}
      </div>
    );
  }
}

SearchResults.propTypes = {
  results: PT.arrayOf(PT.shape({
    id: PT.string.isRequired,
    title: PT.string.isRequired,
    location: PT.string.isRequired,
    text: PT.string.isRequired,
  })),
}

SearchResults.defaultProps = {
  results: [],
}

export default connect(
  state => ({
    results: [
      {
        id: '1',
        title: 'Cintorin',
        location: 'Na Žižkovej hore pri PKO',
        text: '...stary zidovsky cintorin Švédske vydavateľstvo Mittmedia si všimlo, že len pätina predplatných sa predá v prvej hodine po zverejnení článku. Väčšina ľudí si teda zaplatí za obsah neskôr po jeho zverejnení. V Mittmedia preto spustili...',
      },
      {
        id: '2',
        title: 'Iny cintorin',
        location: 'niekde inde',
        text: '...uplne iny cintorin Švédske vydavateľstvo Mittmedia si všimlo, že len pätina predplatných sa predá v prvej hodine po zverejnení článku. Väčšina ľudí si teda zaplatí za obsah neskôr po jeho zverejnení. V Mittmedia preto spustili...',
      }
    ]
  })
)(SearchResults)
