import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { indexOrderComparator } from '../helpers'
import { getLanguage } from './urlParams'

export const SET_CATEGORIES = 'SET_CATEGORIES'

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: {
    categories,
  }
})

export const categoriesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return action.payload.categories

    default:
      return state
  }
}

export const getCategoriesState = state => pathOr([], ['CATEGORIES'], state)

export const getCategories = createSelector(
  getLanguage,
  getCategoriesState,
  (language, categories) => {
    if (language === 'sk') {
      const filtered = categories.map((cat) => {
        const { en, ...rest } = cat
        return rest
      })
      return filtered.sort(indexOrderComparator)
    }

    // en
    const filtered = categories.map((cat) => {
      const { en, ...rest } = cat
      return {
        ...rest,
        title: cat.en.title,
        slug: cat.en.slug,
      }
    })
    return filtered.sort(indexOrderComparator)
  }
)

export const getCategorySlugs = createSelector(
  getCategories,
  (categories) => categories.map(category => category.slug),
)
