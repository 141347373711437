import React, { Component, Fragment } from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { fetchStaticPage, getStaticPageContent } from './duck'
import MailChimp from './MailChimp'
import './_static-page.sass'

class StaticPage extends Component {
  componentDidMount() {
    const { content, fetchStaticPage, slug, language } = this.props
    if (!content) {
      fetchStaticPage(slug, language)
    }
  }

  // after language change, lang in store is updated after fetching new content :/
  componentDidUpdate(prevProps) {
    const { content, fetchStaticPage, slug, language } = this.props
    
    if (prevProps.slug !== slug && !content) {
      fetchStaticPage(slug, language)
    }
  }
  
  render() {
    const { content } = this.props

    if (!content) return (
      <div className="static-page">
        <p>Loading...</p>
      </div>
    )

    const withMailChimp = content.content.replace('&lt;MailChimp /&gt;', MailChimp)

    return (
      <div className="static-page">
        <h2>{content.title}</h2>
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: withMailChimp,
          }} />
      </div>
    );
  }
};

StaticPage.propTypes = {
  language: PT.oneOf(['sk', 'en']),
  content: PT.shape({
    title: PT.string,
    content: PT.string,
  }),
  fetchStaticPage: PT.func.isRequired,
}

StaticPage.defaultProps = {
  content: null,
}

const mapStateToProps = (state, { slug }) => ({
  content: getStaticPageContent(slug)(state),
})

const mapDispatchToProps = {
  fetchStaticPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticPage)
