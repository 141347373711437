import React from 'react'
import PT from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  languageProp,
  placesByCategoriesProp,
  placesByCategoriesDefault,
} from '../../propTypes'
import { getLanguage, getSlugs } from '../../features/urlParams'
import { getTranslationMap } from '../Places/selectors'
import { getTranslationLink } from './helpers'
import { getPathname } from '../Layout/selectors'

export const LanguageSwitch = ({ language, pathname, slugs, translationMap }) => { 
  const args = [
    pathname,
    language,
    translationMap,
    slugs.categorySlug,
    slugs.placeSlug,
  ]
  const skTo = language === 'sk' ? pathname : getTranslationLink(...args)
  const enTo = language === 'en' ? pathname : getTranslationLink(...args)

  return (
    <div className="language-switch">
      <NavLink exact to={skTo} activeClassName="active">SK</NavLink>
      <NavLink to={enTo} activeClassName="active">EN</NavLink>
    </div>
  )
}

LanguageSwitch.propTypes = {
  placesByCategories: placesByCategoriesProp,
  language: languageProp.isRequired,
  slugs: PT.shape({
    categorySlug: PT.string,
    placeSlug: PT.string,
  }).isRequired,
  pathname: PT.string.isRequired,
}

LanguageSwitch.defaultProps = {
  placesByCategories: placesByCategoriesDefault,
  slugs: {},
}

const mapStateToProps = (state) => ({
  language: getLanguage(state),
  slugs: getSlugs(state),
  translationMap: getTranslationMap(state),
  pathname: getPathname(state),
})

export default connect(mapStateToProps)(LanguageSwitch)
