import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { placesProp, placesDefault, languageProp } from '../../propTypes'
import { placeMouseOver, placeMouseOut } from '../map/actions'
import { getIsMobileLayout } from '../Layout/selectors'

const getLink = (item, language, slug) => {
  if (language === 'sk') {
    return `/${slug}/${item.slug}`
  }
  return `/en/${slug}/${item.slug}`
}

const PlacesList = ({
  categorySlug,
  items,
  language,
  placeMouseOut,
  placeMouseOver,
  isMobile,
}) => (
  <ul className="places">
    {items.map(item => (
      <li key={item.slug}>
        <Link
          to={getLink(item, language, categorySlug)}
          onMouseEnter={
            isMobile ? null :  () => {
              placeMouseOver(item.slug)
            }
          }
          onMouseLeave={isMobile ? null : placeMouseOut}
        >
          {item.title}
        </Link>
      </li>
    ))}
  </ul>
)

PlacesList.propTypes = {
  items: placesProp,
  language: languageProp.isRequired,
  categorySlug: PT.string.isRequired,
  isMobile: PT.bool.isRequired,
}

PlacesList.defaultProps = {
  items: placesDefault,
}

export default connect(
  state => ({
    isMobile: getIsMobileLayout(state),
  }),
  {
    placeMouseOver,
    placeMouseOut,
  }
)(PlacesList)
