export const isServer = Boolean(
  typeof window === 'undefined' ||
  !window.document ||
  !window.document.createElement
)

export const supportsLocalStorage = () => {
  try {
      return 'localStorage' in window && window['localStorage'] !== null;
  } catch(e) {
      return false;
  }
}
