import { pathOr } from 'ramda'
import { PUBLIC_URL } from 'admin-modules/build/constants'

export const indexOrderComparator = (a, b) => {
  if (a.index < b.index) {
    return -1
  }
  if (a.index > b.index) {
    return 1
  }
  return 0
}

export const getImageUrl = (image) => typeof image === 'string'
  ? getPlaceOriginalImgUrl(image)
  : getPlaceThumbImgUrl(pathOr('', ['thumbs', 1, 'name'], image))

export const getPlaceOriginalImgUrl = image => `${PUBLIC_URL}/uploads/places/${image}`

export const getPlaceThumbImgUrl = image => `${PUBLIC_URL}/uploads/places/thumbs/${image}`
