export default () => {
  window.fbAsyncInit = () => {
    FB.init({
      appId: '377577189610738',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v3.3'
    })
  }

  const script = document.createElement('script')
  script.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
  document.body.appendChild(script)
}
