import React, { Component } from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { closeBalloon } from './actions'
import { getImageUrl } from '../../helpers'
import './Balloon.sass'

class Balloon extends Component {
  handleClose = (e) => {
    const { onClose, slug } = this.props
    e.preventDefault()
    onClose(slug)
  }

  render() {
    const { imageUrl, title, text } = this.props
    const image = imageUrl && (
      <img
        src={imageUrl}
        alt=""
      />
    )

    return (
      <div className="balloon visible">
        <div className="overlay-content">
          {image}
          <div className="text-content">
            <span onClick={this.handleClose} className="close">Close</span>
            <span className="title">{title}</span>
            <br />
            <span>{text}</span>
          </div>
        </div>
      </div>
    )
  }
}

Balloon.propTypes = {
  imageUrl: PT.string,
  onClose: PT.func.isRequired,
  title: PT.string.isRequired,
  text: PT.string.isRequired,
  state: PT.string.isRequired,
  slug: PT.string.isRequired,
}

const mapDispatchToProps = {
  onClose: closeBalloon,
}

export default connect(null, mapDispatchToProps)(Balloon)
