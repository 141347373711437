import React from "react"
import Slider from "react-slick"
import PT from "prop-types"
import { imagesProp } from '../../propTypes'
import { getImageUrl } from '../../helpers'
import Arrow from './Arrow'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./ImageCarousel.sass"

const ImageCarousel = ({ images, hideArrows }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Arrow to="left" />,
    nextArrow: <Arrow to="right" />,
    arrows: !hideArrows,
  }
  const imgUrls = images
    .map(img => getImageUrl(img))
    .filter(Boolean)

  return (
    <Slider
      className="image-carousel"
      {...settings}
    >
      {imgUrls.map(i => (
        <div className="image-wrap" key={i}>
          <img src={i} alt='' />
        </div>
      ))}
    </Slider>
  )
}

ImageCarousel.propTypes = {
  images: imagesProp,
  hideArrows: PT.bool,
}

ImageCarousel.defaultProps = {
  images: [],
  hideArrows: false,
}

export default ImageCarousel
