"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initAnalytics = void 0;

var _reactGa = _interopRequireDefault(require("react-ga"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var initAnalytics = function initAnalytics() {
  var options = {
    debug: false
  };
  var trackingId = process.env.REACT_APP_GA_CODE;

  _reactGa.default.initialize(trackingId, options);

  _reactGa.default.set({
    appVersion: '0.0.1',
    appName: process.env.REACT_APP_SITE_FOLDER_NAME
  });
};

exports.initAnalytics = initAnalytics;