import PT from 'prop-types'
import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import categoryProp from 'admin-modules/build/proptypes/categoryProp'
import { getLanguage } from './urlParams'
import { getPlaces } from '../containers/Places/selectors';

export const getLatestPlaces = createSelector(
  getLanguage,
  getPlaces,
  (language, places) => {
    const latest = language === 'sk'
      ? [...places].reverse().slice(0, 6)
      : [...places].reverse().filter(({ en }) => en.title).slice(0, 6)
    
    return latest.map(l => ({
      ...l,
      _id: String(l._id),
    }))
  }
)

// const getDateFromMongoObjectId = (objectId) => new Date(parseInt(objectId.substring(0, 8), 16) * 1000)

export const getLastMapUpdate = createSelector(
  state => state.LAST_MAP_UPDATE,
  (timestamp) => {
    return dayjs.unix(timestamp).format('D. M. YYYY')
  }
)

export const latestPlacesPropType = PT.arrayOf(PT.shape({
  _id: PT.string.isRequired,
  category: categoryProp,
  en: PT.shape({
    title: PT.string.isRequired,
    slug: PT.string.isRequired,
  }),
  slug: PT.string.isRequired,
  title: PT.string.isRequired,
}))
