import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { Grid, Row } from 'react-bootstrap'
import Logo from './Logo'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import { toggleMenu, closeMenu } from '../Layout/actions'
import LastUpdate from '../LastUpdate/LastUpdate'
import {
  getIsAppMounted,
  getIsMobileLayout,
  getIsMenuOpened,
  getLayoutHeight,
  getLayoutWidth,
} from '../Layout/selectors'
import { getIsSlovak } from '../../features/urlParams'
import { toggleSearch } from '../Layout/actions'
// import SearchIcon from './search_icon'
import './_header.sass'
import './_header-tablet.sass'

const Header = ({
  appDidMount,
  linkTo,
  naviItems,
  isMobileLayout,
  isOpened,
  viewportHeight,
  viewportWidth,
  onToggleMenu,
  onCloseMenu,
  toggleSearch,
}) => {
  const headerStyle = appDidMount && isMobileLayout && viewportWidth
    ? {
        width: viewportWidth,
      }
    : null
  const style = appDidMount && isMobileLayout && viewportHeight
    ? {
      height: viewportHeight
    }
    : null


  return (
    <header
      className={isOpened ? 'opened' : ''}
      style={headerStyle}
    >
      <Grid>
        <Row>
          <h2 className="logo col-xs-12 col-sm-3">
            <Link to={linkTo} onClick={onCloseMenu}>
              <Logo />
            </Link>
          </h2>
          
          {/* <button
            className="search-icon"
            onClick={toggleSearch}
          >
            <SearchIcon />
          </button> */}

          <a
            href=""
            className="hamburger"
            onClick={(e) => {
              e.preventDefault()
              onToggleMenu()
            }}
          >
            <span className="icon" />
          </a>
          <nav
            className="main-menu col-xs-2 col-sm-9"
            style={style}
          >
            <ul>
              {naviItems.map(item => (
                <li key={item.href}>
                  <NavLink
                    to={item.href}
                    onClick={onCloseMenu}
                    activeClassName="active"
                    exact
                  >
                    {item.text}
                  </NavLink>
                </li>
              ))}
            </ul>

            <div className="bottom clearfix">
              <ul className="social clearfix">
                <li><a className="fb" href="//www.facebook.com/MPBA.sk" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                <li><a className="instagram" href="//www.instagram.com/mpba.sk/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              </ul>
              <LanguageSwitch />
              <LastUpdate />
            </div>

          </nav>
        </Row>
      </Grid>

    </header>
  )
}

Header.propTypes = {
  isMobileLayout: PT.bool.isRequired,
  viewportWidth: PT.number.isRequired,
  viewportHeight: PT.number.isRequired,
  title: PT.string.isRequired,
  linkTo: PT.string.isRequired,
  naviItems: PT.arrayOf(
    PT.shape({
      href: PT.string.isRequired,
      text: PT.string.isRequired,
    }),
  ),
  isOpened: PT.bool.isRequired,
  onToggleMenu: PT.func.isRequired,
  onCloseMenu: PT.func.isRequired,
  toggleSearch: PT.func.isRequired,
}

Header.defaultProps = {
  isMobileLayout: true,
  naviItems: [],
}

const mapStateToProps = (state) => ({
  appDidMount: getIsAppMounted(state),
  linkTo: getIsSlovak(state) ? '/' : '/en',
  title: "M_P_ BA",
  isMobileLayout: getIsMobileLayout(state),
  isOpened: getIsMenuOpened(state),
  viewportHeight: getLayoutHeight(state),
  viewportWidth: getLayoutWidth(state),
})

const mapDispatchToProps = {
  onCloseMenu: closeMenu,
  onToggleMenu: toggleMenu,
  toggleSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
