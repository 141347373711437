import React, { useState } from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getIsAppMounted, getIsSearchOpened } from '../Layout/selectors'
import SearchIcon from '../Header/search_icon'
import './search-input.sass'

const Search = ({ appDidMount, isOpen, history }) => {
  const [text, updateText] = useState('')

  if (!appDidMount || !isOpen) return null

  return (
    <div className="search-input">
      <input
        type="text"
        placeholder="Hľadať"
        value={text}
        onChange={(e) => {
          updateText(e.target.value)
        }}
      />
      
      <button
        className="search-icon"
        onClick={() => {
          history.push(`/search/${encodeURIComponent(text)}`)
        }}
      >
        <SearchIcon />
      </button>
    </div>
  )
}

Search.propTypes = {
  appDidMount: PT.bool.isRequired,
  isOpen: PT.bool.isRequired,
  history: PT.shape({
    push: PT.func.isRequired,
  }).isRequired,
}

export default connect(
  state => ({
    appDidMount: getIsAppMounted(state),
    isOpen: getIsSearchOpened(state),
  })
)(withRouter(Search))
