import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import debounce from 'javascript-debounce'
import loadable from '@loadable/component'
import { MOBILE_MAP_HEIGHT } from '../../constants'
import ExpandBtn from '../ExpandBtn/ExpandBtn'
import HomeContent from '../Home/HomeContent'
import { getIsMobileLayout, getIsLayoutExpanded, getLayoutHeight } from '../Layout/selectors'
import { mapLeftEdgeChanged } from '../Layout/actions'
import PageHead from '../PageHead/PageHead'
import StaticPage from '../StaticPage/StaticPage'
import SearchResults from '../Search/SearchResults'

const GotTip = loadable(() => import('../GotTip/GotTip'))
const WantMap = loadable(() => import('../WantMap/WantMap'))
const CommentForm = loadable(() => import('../CommentForm/CommentForm'))

export class PageContainer extends React.Component {
  state = {
    style: {}
  }

  constructor(props) {
    super(props)
    this.debouncedResize = debounce(this.handleResize, 100)
  }

  componentDidMount() {
    this.debouncedResize()
    window.addEventListener('resize', this.debouncedResize)
    this.setStyle()
  }

  componentDidUpdate(prevProps) {
    const { isMobileLayout, layoutExpanded, viewportHeight } = this.props

    if (
      isMobileLayout !== prevProps.isMobileLayout ||
      layoutExpanded !== prevProps.layoutExpanded ||
      viewportHeight !== prevProps.viewportHeight
    ) {
      this.setStyle()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize)
  }

  setStyle() {
    const { layoutExpanded, isMobileLayout, viewportHeight } = this.props

    if (isMobileLayout) {
      const containerHeightVh = `${(1 - MOBILE_MAP_HEIGHT) * 100}vh`
      const y = layoutExpanded
        ? `${viewportHeight}px`
        : `calc(${viewportHeight}px - ${containerHeightVh} + 62px)`

      const transform = `translate3d(0, ${y}, 0)`
      const style = {
        style: {
          WebkitTransform: transform,
          transform,
          minHeight: `calc(${viewportHeight}px - ${containerHeightVh} - 62px)`,
        }
      }

      this.setState(style)
      return
    }

    this.setState({ style: {} })
  }

  handleResize = () => {
    // for desktop get right edge and send it to parent
    if (!this.container) return

    const rect = this.container.getBoundingClientRect()
    const { isMobileLayout, onLeftChanged } = this.props
    const x = isMobileLayout ? 0 : rect.left + rect.width
    onLeftChanged(x)
  }

  handleRef = (ref) => { this.container = ref }

  getMatch() {
    const { url } = this.props.match
    const isSkPage = url.startsWith('/page/')
    const isEnPage = url.startsWith('/en/page/')
    
    if (isSkPage || isEnPage) {
      const slug = isEnPage
        ? url.split('/en/page/')[1]
        : url.split('/page/')[1]

      return <StaticPage slug={slug} language={isSkPage ? 'sk' : 'en'} />
    }

    const isSkComment = url.startsWith('/komentar/')
    const isEnComment = url.startsWith('/comment/')

    if (isSkComment || isEnComment) {
      const slug = isEnComment
        ? url.split('/comment/')[1]
        : url.split('/komentar/')[1]

      return <CommentForm slug={slug} />
    }

    if (url.startsWith('/search/')) {
      const query = url.split('/search/')[1]
      if (query) {
        return <SearchResults query={query} />
      }
    }

    switch(url) {
      case '/mam-tip':
      case '/en/got-tip':
        return <GotTip />
      case '/chcem-mapu':
      case '/en/want-map':
        return <WantMap />
      default:
        return <HomeContent />
    }
  }

  render() {
    return (
      <div
        ref={this.handleRef}
        className="page-container"
        style={this.state.style}
      >
        <PageHead />
        <ExpandBtn />
        
        {this.getMatch()}
      </div>
    )
  }
}

PageContainer.propTypes = {
  isMobileLayout: PT.bool.isRequired,
  layoutExpanded: PT.bool.isRequired,
  onLeftChanged: PT.func.isRequired,
  viewportHeight: PT.number,
}

PageContainer.defaultProps = {
  viewportHeight: 0,
}

const mapStateToProps = (state) => ({
  isMobileLayout: getIsMobileLayout(state),
  layoutExpanded: getIsLayoutExpanded(state),
  viewportHeight: getLayoutHeight(state),
})

const mapDispatchToProps = {
  onLeftChanged: mapLeftEdgeChanged,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageContainer))

