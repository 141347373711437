import React from 'react'
import PT from 'prop-types'
import { connect } from 'react-redux'
import {
  languageProp,
  placesByCategoriesProp,
  placesByCategoriesDefault,
} from '../../propTypes'
import PlaceCategory from './PlaceCategory'
import { getCategorySlugs } from '../../features/categories'
import { getPlacesByCategories } from './selectors';
import { getLanguage, getSlugs } from '../../features/urlParams';

export const PlacesByCategories = ({ categorySlug, categorySlugs, placesByCategories, language }) => {
  return (
    <ul>
      {categorySlugs.map((slug, index) => (
        <PlaceCategory
          key={slug}
          index={index}
          slug={slug}
          title={placesByCategories[slug].title}
          items={placesByCategories[slug].items}
          opened={slug === categorySlug}
          language={language}
        />
      ))}
    </ul>
  )
}

PlacesByCategories.propTypes = {
  categorySlug: PT.string,
  categorySlugs: PT.arrayOf(PT.string),
  language: languageProp.isRequired,
  placesByCategories: placesByCategoriesProp,
}

PlacesByCategories.defaultProps = {
  categorySlug: '',
  categorySlugs: [],
  placesByCategories: placesByCategoriesDefault,
}

const mapStateToProps = (state) => ({
  categorySlug: getSlugs(state).categorySlug,
  categorySlugs: getCategorySlugs(state),
  language: getLanguage(state),
  placesByCategories: getPlacesByCategories(state),
})

export default connect(mapStateToProps)(PlacesByCategories)