import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PT from 'prop-types'
import cx from 'classnames'
import stripHtml from "string-strip-html"
import { languageProp, imagesProp } from '../../propTypes'
import { getLanguage, getSlugs } from '../../features/urlParams'
import { getIsMobileLayout, getIsAppMounted } from '../Layout/selectors'
import { fetchPlaceData } from './duck'
import { getPlaceData } from '../Places/selectors'
import { getImageUrl } from '../../helpers'
import PageHead from '../PageHead/PageHead'
import ShareBtn from '../ShareBtn/ShareBtn'
import { getTextBySlug } from '../../features/texts'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import './_placeDetail.sass'

export class PlaceDetail extends React.Component {
  fetch() {
    const { fetchData, language, placeSlug } = this.props
    fetchData(language, placeSlug)
  }

  componentDidMount() {
    if (this.props.isMobile) {
      window.scrollTo(0, 0)
    }
    if (this.props.placeSlug) {
      this.fetch()
    }
  }

  componentDidUpdate(prevProps) {
    const { language, placeSlug } = this.props
    const prevSlug = prevProps.placeSlug

    if (placeSlug && (prevProps.language !== language || placeSlug !== prevSlug)) {
      this.fetch()
    }
  }

  render() {
    const { appDidMount, categorySlug, formText, title, content, locations, images, isMobile, language, placeSlug } = this.props

    if (!placeSlug) return null

    const locationsClass = (locations.length > 1) ? 'multiple' : 'single'

    const urlStart = (language === 'en') ? '/en/' : '/'
    const backLinkUrl = urlStart + categorySlug
    const safeHtml = content
    const metaTitle = `${title} - M_P_ BA`

    return (
      <Fragment>
        <PageHead
          ogDesc={stripHtml(content)}
          ogImage={getImageUrl(images[0])}
          ogTitle={metaTitle}
          title={metaTitle}
        />

        {!isMobile && appDidMount && <ImageCarousel images={images} hideArrows={isMobile} />}

        <div className="wrapper">
          <Link to={backLinkUrl} className="back">Back</Link>
          <h2>{title}</h2>

          {isMobile && locations.length === 0 && images.length > 0 && (
            <img className="place-detail-mobile-image" src={getImageUrl(images[0])} />
          )}

          <div className={cx('locations', locationsClass)}>
            {locations.map(location => (
              <h3
                key={`${location.lat}${location.lng}`}
                className="desc"
              >
                {location.desc}
              </h3>
            ))}
          </div>

          <div dangerouslySetInnerHTML={{ __html: safeHtml }} />

          <Link
            to={language === 'en' ? `/comment/${placeSlug}` : `/komentar/${placeSlug}`}
            className="form-link"
          >
            {formText}
          </Link>

          {global.location.href && (
            <ShareBtn to={global.location.href} />
          )}
        </div>
      </Fragment>
    )
  }
}

PlaceDetail.propTypes = {
  appDidMount: PT.bool.isRequired,
  images: imagesProp,
  isMobile: PT.bool.isRequired,
  language: languageProp.isRequired,
  locations: PT.arrayOf(PT.shape({
    desc: PT.string.isRequired,
    lat: PT.number.isRequired,
    lng: PT.number.isRequired,
  })),
  formText: PT.string.isRequired,
  title: PT.string,
  content: PT.string,
}

PlaceDetail.defaultProps = {
  title: '',
  content: 'Loading...',
  images: [],
  locations: [],
}

const mapStateToProps = (state) => {
  const { categorySlug, placeSlug } = getSlugs(state)
  return {
    appDidMount: getIsAppMounted(state),
    categorySlug,
    placeSlug,
    isMobile: getIsMobileLayout(state),
    language: getLanguage(state),
    formText: getTextBySlug('place-detail-suggestion')(state),
    ...getPlaceData(placeSlug)(state),
  }
}

const mapDispatchToProps = {
  fetchData: fetchPlaceData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceDetail)
