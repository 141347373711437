import { createSelector } from 'reselect';
import { pathOr } from 'ramda';

export const UPDATE_SLUGS = 'UPDATE_SLUGS'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const updateSlugs = (categorySlug, placeSlug) => ({
  type: UPDATE_SLUGS,
  payload: {
    categorySlug,
    placeSlug,
  }
})

export const initialState = {
  categorySlug: '',
  placeSlug: '',
  language: '',
}

export const urlSlugsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        LANGUAGE: payload.language,
      }

    case UPDATE_SLUGS:
      return {
        ...state,
        categorySlug: payload.categorySlug,
        placeSlug: payload.placeSlug,
      }

    default:
      return state;
  }
}

export const getSlugs = createSelector(
  state => pathOr({}, ['URL_PARAMS'], state),
  params => ({
    categorySlug: params.categorySlug || '',
    placeSlug: params.placeSlug || '',
  })
)

export const changeLanguage = (language) => ({
  type: CHANGE_LANGUAGE,
  payload: {
    language,
  }
})

export const languageReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.payload.language

    default:
      return state
  }
}

export const getLanguage = (state) => pathOr('sk', ['URL_PARAMS', 'LANGUAGE'], state)

export const getIsSlovak = (state) => getLanguage(state) === 'sk'

