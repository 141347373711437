import React from 'react'

export default () => (
  <svg fill="#fff" version="1.1" x="0px" y="0px" viewBox="0 0 1040 732">
		<path d="M74.17,83.16c0-7.35,6.3-13.66,13.66-13.66h3.15c5.95,0,10.16,3.15,12.96,7.36l87.56,131.69l87.56-131.69
			c2.8-4.55,7.36-7.36,12.96-7.36h3.15c7.36,0,13.66,6.3,13.66,13.66v220.31c0,7.71-6.3,14.01-13.66,14.01
			c-7.71,0-14.01-6.65-14.01-14.01V120.64l-78.46,115.23c-3.15,4.55-6.65,7-11.56,7c-4.9,0-8.76-2.45-11.91-7l-78.11-114.88v182.83
			c0,7.71-5.95,13.66-13.66,13.66c-7.36,0-13.31-5.95-13.31-13.66V83.16z"/>
		<path d="M331.6,360.56c0-5.95,5.25-10.86,11.21-10.86h201.55c5.95,0,11.21,4.9,11.21,10.86
			c0,6.3-5.25,11.21-11.21,11.21H342.81C336.85,371.77,331.6,366.87,331.6,360.56z"/>
		<path d="M587.33,84.56c0-7.71,6.3-14.01,13.66-14.01h78.11c55.34,0,91.77,29.42,91.77,77.76v0.7
			c0,52.89-44.13,80.21-96.32,80.21H615v74.25c0,7.71-6.3,14.01-14.01,14.01c-7.36,0-13.66-6.3-13.66-14.01V84.56z M675.59,204
			c40.63,0,67.25-21.72,67.25-53.94v-0.7c0-35.03-26.27-53.24-65.85-53.24H615V204H675.59z"/>
		<path d="M763.71,360.56c0-5.95,5.25-10.86,11.21-10.86h201.55c5.95,0,11.21,4.9,11.21,10.86
			c0,6.3-5.25,11.21-11.21,11.21H774.92C768.97,371.77,763.71,366.87,763.71,360.56z"/>
		<path d="M74.17,456.56c0-7.71,6.3-14.01,13.66-14.01h90.71c28.02,0,50.09,8.06,64.1,21.72
			c10.16,10.51,15.76,23.47,15.76,39.23v0.7c0,31.87-19.61,48.33-38.88,56.74c29.07,8.76,52.54,25.57,52.54,59.19v0.7
			c0,42.03-35.38,66.9-88.96,66.9H87.83c-7.35,0-13.66-6.3-13.66-14.01V456.56z M173.99,551.83c33.27,0,56.39-15.06,56.39-43.78v-0.7
			c0-24.17-19.26-39.93-54.29-39.93h-74.6v84.41H173.99z M183.8,662.86c37.13,0,60.24-16.46,60.24-43.78v-0.7
			c0-26.62-22.42-42.03-65.15-42.03h-77.41v86.51H183.8z"/>
		<path d="M331.24,669.52l99.47-217.51c3.5-7.71,8.76-12.26,17.51-12.26h1.4c8.41,0,14.01,4.55,17.16,12.26l99.47,217.15
			c1.05,2.45,1.75,4.9,1.75,7.01c0,7.36-5.95,13.31-13.31,13.31c-6.65,0-11.21-4.55-13.66-10.16l-25.57-56.39h-134.5l-25.57,57.09
			c-2.45,5.95-7,9.46-13.31,9.46c-7,0-12.61-5.6-12.61-12.61C329.49,674.77,329.84,672.32,331.24,669.52z M504.62,597.72
			l-56.39-125.39l-56.39,125.39H504.62z"/>
	</svg>
)
